<template>
  <div class="dapp-box">
    <div class="w">
      <div class="wl-title3">
        <!-- 探索PSC -->
        {{ $t("study.pscBox3T1") }}
      </div>

      <div class="box">
        <div class="item">
          <div class="t">
            <!-- 基于PSC创造一些玩意 -->
            {{ $t("study.pscBox3T2") }}
          </div>
          <div class="p pfont">
            <!-- 如果您想尝试进行PSC相关 -->
            {{ $t("study.pscBox3P") }}
          </div>
          <a href="javascript:;">
            <div class="but" @click="uti.goPath(store.link.study.pscBut2)">
              <!-- 开始构建 -->
              {{ $t("study.pscBox3But") }}
            </div>
          </a>
        </div>
        <div class="img">
          <img src="../../assets/images/v4/study/pscBox3bg1.png" alt="" />
        </div>
      </div>
      <div class="box">
        <div class="img">
          <img src="../../assets/images/v4/study/pscBoxbg2.png" alt="" />
        </div>
        <div class="item">
          <div class="t">
            <!-- PSC社区 -->
            {{ $t("study.pscBox3BoxT") }}
          </div>
          <div class="p pfont">
            <!-- 我们的社区成员来自 -->
            {{ $t("study.pscBox3BoxP") }}
          </div>
          <a href="javascript:;">
            <div class="but" @click="uti.goPath('/com')">
              <!-- 我们的社区 -->
              {{ $t("study.pscBox3BoxB") }}
            </div>
            
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>
<style lang="less" scoped>
.dapp-box {
  padding-top: 257px;
  padding-bottom: 250px;
  background-image: url("../../assets/images/v4/home/box2Bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 60%;
  .box {
    height: 529px;
    margin-bottom: 42px;
    // background-color: pink;
    display: flex;
    .item {
      flex: 1;
      .t {
        font-size: 60px;
        margin-top: 110px;
        font-weight: 500;
        color: #333333;
      }
      .p {
        width: 581px;
        margin-top: 48px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: #999999;
      }
      .but {
        display: inline-block;
        padding: 0 34px;
        height: 54px;
        line-height: 54px;
        background: linear-gradient(90deg, #015eea, #00c0fa);
        box-shadow: 0px 5px 20px 0px rgba(98, 158, 211, 0.5);
        border-radius: 27px;
        font-size: 20px;
        font-weight: 400;
        margin-top: 73px;
        color: #ffffff;
      }
    }
    .img {
      padding: 0 70px;
    }
  }
}
</style>
