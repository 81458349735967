<template>
  <div class="com-item">
    <div class="w">
      <!-- PSC简介 -->
      <!-- <div class="t1">
        {{ $t("study.pscBox1T1") }}
      </div> -->
      <div class="t2">
        <!-- PSC向所有人开放 -->
        {{ $t("study.pscBox1T2") }}
      </div>
      <div class="p pfont">
        <!-- PolySmartChain是一个快速、高吞吐量的开源智能合约平台，它结合了突破性的技术，为去中心化的应用程序、系统和社会提供无与伦比的安全性和可持续性。您只需要一个钱包就可以一起参与。 -->

        {{ $t("study.pscBox1P") }}
      </div>
      <div class="butBox">
        <div class="but1" @click="uti.goPath(store.link.study.pscBut1)">
          <!-- 探索PSC -->
          {{ $t("study.pscBox1But") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  height: 100vh;
  box-shadow: 0px 2px 8px 0px rgba(212, 217, 250, 0.5);
  background-color: #e8f3ff;
  background-image: url("../../assets/images/v4/study/pscBox1BG.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  .w {
    // background-color: pink;
    height: 100vh;
    padding-top: 200px;
    box-sizing: border-box;
    .t1 {
      height: 29px;
      font-size: 30px;
      font-weight: 400;
      color: #333333;
    }
    .t2 {
      // margin-top: 65px;
      font-size: 70px;
      font-weight: bold;
      color: #333333;
    }
    .p {
      width: 783px;
      font-size: 24px;
      line-height: 35px;
      font-weight: 500;
      margin-top: 23px;
      color: #666666;
    }
    .butBox {
      display: flex;
      margin-top: 61px;
      text-align: center;
      .but1 {
        display: inline-block;
        padding: 0 42px;
        height: 54px;
        line-height: 54px;
        background: linear-gradient(-90deg, #10c1fc, #3f65f9);
        box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
        border-radius: 27px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        margin-right: 50px;
      }
    }
  }
}
</style>
